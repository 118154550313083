import { AnimatePresence } from "framer-motion";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// Libraries
import { Route, Routes, useLocation } from "react-router-dom";
import retina from "retinajs";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";
// Context
import GlobalContext from "./Context/Context";

// Home
const HomePage = lazy(() => import("./Pages/HomePage"));
const AboutUsPage = lazy(() => import("./Pages/AboutUsPage"));
const ServicesPage = lazy(() => import("./Pages/ServicesPage"));
const ContactUsPage = lazy(() => import("./Pages/ContactUsPage"));
const NotFoundPage = lazy(() => import("./Pages/404"));
const PrivacyPage = lazy(() => import("./Pages/Privacy"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        <Helmet>
          <meta
            name="description"
            content="Servicios de contabilidad y peritaje tributario en San Felipe, Chile. Contadores expertos en asesoría tributaria y financiera."
          />
          <meta
            name="keywords"
            content="Contabilidad San Felipe, Contador San Felipe, Peritaje Tributario, Auditoría Chile"
          />
        </Helmet>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  {/* Currently used routes */}
                  <Route
                    path="/"
                    element={<HomePage style={{ "--base-color": "#0076bc" }} />}
                  />
                  <Route
                    path="/contact"
                    element={
                      <ContactUsPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/about-us"
                    element={
                      <AboutUsPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/services"
                    element={
                      <ServicesPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/privacy"
                    element={
                      <PrivacyPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
